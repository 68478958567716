import React, { FC } from 'react';
import './FullCredits.scss';
import SectionTitle from '../../../components/common/SectionTitle';
import Span from '../../../components/pagesComponents/CaseStudy/Span';
import FreshiiLayouts from '../../../components/PagesLayouts/FreshiiLayouts';
import Links from '../../../components/pagesComponents/CaseStudy/Links';
import FixedButton from '../../../components/common/FixedButton';
import { TNamesArray } from '../../../types/types';
import { useScrollTo } from '../../../hooks/useScrollToTop';
import { useGetScreenWidth } from '../../../hooks/useGetScreenWidth';

const FullCredits: FC = () => {
    const clientsArray: TNamesArray = [
        { id: 0, name: 'Umair Vanthaliwala, ', position: 'VP of Digital', subtype: 'ml-5' },
        { id: 1, name: 'Ali Aqueel, ', position: 'Product Manager', subtype: 'ml-5' },
        {
            id: 2,
            name: 'Ankita Jain,',
            position: 'Product Manager',
            subtype: 'ml-5',
        },
    ];

    const employeesArray: TNamesArray = [
        { id: 0, name: 'Rohit Mehta,', position: 'Product Designer', subtype: 'ml-5' },
        { id: 1, name: 'Trevor Vesleno,', position: 'System Specialist', subtype: 'ml-5' },
        { id: 2, name: 'Prabhjot Maini,', position: 'Technical Analyst', subtype: 'ml-5' },
    ];

    const developersArray: TNamesArray = [
        { id: 0, name: 'Anton Shloma,', position: 'Software Engineer', subtype: 'ml-5' },
        { id: 1, name: 'Denys Klymenko,', position: 'Software Engineer', subtype: 'ml-5' },
        { id: 2, name: 'Vitalii Dokalenko,', position: 'Software Engineer', subtype: 'ml-5' },
        { id: 3, name: 'Bohdan Vorobiovskyi,', position: 'Software Engineer', subtype: 'ml-5' },
        { id: 4, name: 'Egor Shloma,', position: 'Software Engineer', subtype: 'ml-5' },
        { id: 5, name: 'Vladimir Kravchenko,', position: 'Software Engineer', subtype: 'ml-5' },
        { id: 6, name: 'Sergey Klymenko,', position: 'Software Engineer', subtype: 'ml-5' },
        { id: 7, name: 'Dmytro Bohdan,', position: 'Software Engineer', subtype: 'ml-5' },
        { id: 8, name: 'Mykhaylo Gnylorybov,', position: 'Software Engineer', subtype: 'ml-5' },
        { id: 9, name: 'Andrew Bogaevskyi,', position: 'Software Engineer', subtype: 'ml-5' },
        { id: 10, name: 'Oleksandr Spodarenko,', position: 'Software Engineer', subtype: 'ml-5' },
        { id: 11, name: 'Sasha Kozlov,', position: 'Software Engineer', subtype: 'ml-5' },
        { id: 12, name: 'Valerii Liutyi,', position: 'Software Engineer', subtype: 'ml-5' },
    ];

    const managersArray: TNamesArray = [
        { id: 0, name: 'Yurii Drozd,', position: 'CEO', subtype: 'ml-5' },
        { id: 1, name: 'Oleksii Hnatienko,', position: 'TPM', subtype: 'ml-5' },
    ];

    const qaArray: TNamesArray = [
        { id: 0, name: 'Maksym Martyniuk', position: 'Senior QA', subtype: 'ml-5' },
        { id: 1, name: 'Dmitry Deleur,', position: 'Senior QA', subtype: 'ml-5' },
        { id: 2, name: 'Ruslana Chumachenko,', position: 'Senior QA', subtype: 'ml-5' },
    ];

    const devOpsArray: TNamesArray = [
        { id: 0, name: 'Denys Tomakhin', position: 'Senior DevOps', subtype: 'ml-5' },
    ];

    const isMobile = useGetScreenWidth();
    const scrollValue = isMobile ? 2240 : 1240;

    useScrollTo(0, scrollValue);

    return (
        <FreshiiLayouts>
            <div className="scope-of-project__wrapper">
                <SectionTitle type="primary" title="Full credits" />
                <div className="scope-of-project__body">
                    <div className="scope-of-project__description scope-of-project__description--mt">
                        <div className="scope-of-project__item">
                            <SectionTitle subtype="mb-30" type="secondary" title="Freshii" />
                            <Span type="headline" subtype="mb-10">
                                Client managers
                            </Span>
                            {clientsArray.map((client) => (
                                <div key={client.id} className="scope-of-project__name">
                                    <Span type="span" subtype="">
                                        {client.name}
                                    </Span>
                                    <Span type="paragraph" subtype={client.subtype}>
                                        {client.position}
                                    </Span>
                                </div>
                            ))}

                            <Span type="headline" subtype="m-10">
                                Client employees
                            </Span>
                            {employeesArray.map((client) => (
                                <div key={client.id} className="scope-of-project__name">
                                    <Span type="span" subtype="">
                                        {client.name}
                                    </Span>
                                    <Span type="paragraph" subtype={client.subtype}>
                                        {client.position}
                                    </Span>
                                </div>
                            ))}
                        </div>

                        <div className="scope-of-project__item">
                            <SectionTitle type="secondary" subtype="mb-30" title="Blackbird" />
                            <Span type="headline" subtype="mb-10">
                                Managers
                            </Span>
                            {managersArray.map((client) => (
                                <div key={client.id} className="scope-of-project__name">
                                    <Span type="span" subtype="">
                                        {client.name}
                                    </Span>
                                    <Span type="paragraph" subtype={client.subtype}>
                                        {client.position}
                                    </Span>
                                </div>
                            ))}

                            <Span type="headline" subtype="m-10">
                                Developers
                            </Span>
                            {developersArray.map((client) => (
                                <div key={client.id} className="scope-of-project__name">
                                    <Span type="span" subtype="">
                                        {client.name}
                                    </Span>
                                    <Span type="paragraph" subtype={client.subtype}>
                                        {client.position}
                                    </Span>
                                </div>
                            ))}

                            <Span type="headline" subtype="m-10">
                                Quality control
                            </Span>
                            {qaArray.map((client) => (
                                <div key={client.id} className="scope-of-project__name">
                                    <Span type="span" subtype="">
                                        {client.name}
                                    </Span>
                                    <Span type="paragraph" subtype={client.subtype}>
                                        {client.position}
                                    </Span>
                                </div>
                            ))}
                            <Span type="headline" subtype="m-10">
                                DevOps
                            </Span>
                            {devOpsArray.map((client) => (
                                <div key={client.id} className="scope-of-project__name">
                                    <Span type="span" subtype="">
                                        {client.name}
                                    </Span>
                                    <Span type="paragraph" subtype={client.subtype}>
                                        {client.position}
                                    </Span>
                                </div>
                            ))}
                        </div>
                    </div>
                    <Links />
                </div>
            </div>
            <FixedButton />
        </FreshiiLayouts>
    );
};

export default FullCredits;
